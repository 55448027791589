// ARIANE INIT

ariane_init( "#content", "#icon_container", "#content_preview", '#fileMng_container', '#relatedMng_container' );

ariane_registerCallback( "load_before", function( id ) {	
	ariane_hide_popovers( );
	ariane_loadingSpinner( );
	ariane_unregisterEventListeners("validate");
	return true;
} );


ariane_registerCallback( "load_error", function( id ) {		 
	if( id != 'user' && id != "connection" && id != '500' ) {
		alertify
			.okBtn(_( "button_confirm_ok" ))			
			.alert( '<div style="margin:10px;"><h2><span class="glyphicon glyphicon-warning-sign"></span></h2>' + _( "load_error_text") + '</div>' );
	}
	if( id == 'user' ) { //error-num
		window.location.replace = "/login-logout";
	}
	if( id = "connection" ) {
		alertify
			.okBtn(_( "button_confirm_ok" ))			
			.alert( '<div style="margin:10px;"><h2><span class="glyphicon glyphicon-warning-sign"></span></h2>' + _( "load_error_connection" ) + '</div>' );
	}
	if( id == '500' ) {
		window.location.replace = "/error-500";
	}
} );

ariane_registerCallback( "preview_error", function( id ) {
	alertify
		.okBtn(_( "button_confirm_ok" ))		
		.alert( '<div style="margin:10px;"><h2><span class="glyphicon glyphicon-eye-close"></span></h2>' + _( "preview_error" ) + '</div>' );
} );

ariane_registerCallback( "save_error", function( id) {	
	if( id != "user" && id != "internal" && id != "connection" ) {
		var text = $( '.nav-pills a[href="#ariane_tab_' + id + '_content"]' ).text( );
		var lang = ariane_getTabLang( id );		
		if( lang ) {
			var langname = $( '.nav-pills a[href="#ariane_tab_' + id + '_content"]' ).parent( 'li' ).parent( 'ul' ).siblings( 'a' ).text( );
			text = langname + "&nbsp" + text;
		}
		alertify
			.okBtn(_( "button_confirm_ok" ))			
			.alert( '<div style="margin:10px;"><h2><span class="glyphicon glyphicon-floppy-remove"></span></h2>' + _( "save_error_text" ) + '<strong><br/>' + text + '</strong></div>' );
		$( '.nav-pills a[href="#ariane_tab_' + id + '_content"]' ).tab( 'show' );		
	}
	if( id == "user" ) {
		alertify
			.okBtn(_( "button_confirm_ok" ))			
			.alert( _( "save_error_user" ) );
	}

	if( id == "internal" ) {
		alertify
			.okBtn(_( "button_confirm_ok" ))			
			.alert( _( "save_error_internal" ) );
	}

	if( id == "connection" ) {
		alertify
			.okBtn(_( "button_confirm_ok" ))			
			.alert( '<div style="margin:10px;"><h2><span class="glyphicon glyphicon-floppy-remove"></span></h2>' + _( "save_error_connection" ) + '</div>' );
	}
} );


ariane_registerCallback( "save_after", function( id, name, icon ) {	
	ariane_modified = false;	
	alertify.success( _( "changes_saved" ) );	
	ariane_event('save_after', null);
} );

ariane_registerCallback('load_after', function(id) {
	// init all popovers
	$('[data-toggle="popover"]').popover();
	
	// for f***ing IE
	if(BrowserDetect.browser == "Explorer") {
		var ieHeight = $(window).height();
		var headerHeight = $("body > header").height() + $("#header").height();		
		$("#content .main-panel").height(ieHeight - headerHeight);	
	}
	
	var hash = window.location.hash;
	if(hash === '') {
		// remove fragment as much as it can go without adding an entry in browser history:
		window.location.replace("#");

		// slice off the remaining '#' in HTML5:    
		if (typeof window.history.replaceState == 'function') {
		  history.replaceState({}, '', window.location.href.slice(0, -1));
		}
	// go to concrete tab in the header nav
	} else if(hash !== '') {
		$("#page-navigator a[href='"+hash+"']").tab('show');
	}
	
});
// ----END OF ARIANE INIT----


/* =======================================================
   EVENTS
   ======================================================= */
  
// check content for unsaved content before load
$(window).on("beforeunload", function() {
	if(ariane_modified) {
		return (_("alert_unsaved_changes"));
	}
});

// kick out user, if backend return 401
$(document).ajaxError(function(event, xhr, settings, thrownError) {
  if(xhr.status == "401") {  		
		setTimeout(function() {
			window.location.href = "/accessdenied";						
		}, 2000);
	}	
});

// change user languages
$(document).on('submit', '#user-settings > form', function(e, par) {	
	par = par || {};
	if(!par.data) {
		e.preventDefault();	
		var lang = $(this).find("option:selected").val();
		lang = lang.slice(0,2);		
		$.when(		
			i18n.userSelected(lang)
		).
		then(function() {			
			$(e.currentTarget).trigger('submit', {'data':true});
		});	
	}	
});

// remove application
$( document ).on( 'click', '#remove-app', function( e ) {
	e.preventDefault( );
	var id = $( this ).attr( 'data-id' );

	alertify
		.okBtn(_('button_delete'))
		.cancelBtn(_("button_cancel"))
		.confirm( _('application_delete_question'), function( e ) {		
			$.ajax( {
				url: "/app/" + id,
				method: 'POST',
				data: {
					_method: 'delete'
				},
				beforeSend: function() {					
					var target = $('body >.vbox');
					target.empty();					
					alertify.log(_("application_deleting"), "", 0);
					new Spinner(ariane_spinner_opts).spin(target[0]);					
				},
				success: function( text ) {					
					if( text.status == 'ok' ) {	
						setTimeout(function() {									
						var loc = window.location;
						window.location = loc.protocol + "//" + loc.hostname;
						}, 800);						
					} else {
						$(".alertify-log-show").hide();
						alertify.error( _(text.message) );
					}
				},
				error: function( text ) {
					$(".alertify-log-show").hide();
					alertify.error( _(text.message) );
				}				
			} );
		}, function() {
			return false;
		});
} );

// change node icon
$(document).on('click', '#ariane-pageIcon', function() {	
	ariane_selectIcon($(this).children("img"), changeNodeIcon);	
});

// save page
$(document).on('click', '#save-page', function() {	
	var id = $(this).attr('data-id');
	ariane_savePage(id);
});

// click on the application in the list
$(document).on("click", ".app-item", function() {
	var href = $(this).find('a').attr('href');
	window.location.href = href;
});

// show dialog for try and publish
$(document).on("click", "a[data-target='#modal_wizard']", function(e) {	
	e.preventDefault();
	var type = $(this).attr("data-type");
	var name = $(this).text();	
	showWizardDialog(type, name);
});

// change icon to text on root help page on hover event
$(document).on({ mouseenter: function() {	
		var panel = $(this);
		panel.find('.mouseleave').hide();
		panel.find('.mouseenter').show();
	},
	mouseleave: function() {
		var panel = $(this);
		panel.find('.mouseleave').show();
		panel.find('.mouseenter').hide();
	}
}, '.hoverable');

// close alert at the top of list apps
$("#tree-list-alert").on("click", function() {
	// hide alert
	$("#alert-wrapp").remove();
	// update DB
	$.ajax({
		type: "POST",
		url: "/user/updateExtra",
		data: {'tree_list_alert_closed': true},
		success: function(json) {
			console.log(json);
		}
	});
});

// switch tab in room booking form
$(document).on('click', "#room-booking-tab", function(e) {
	e.preventDefault();	
	$('a[href="#ariane_tab_room_types_content"]').click();	
});


/* =======================================================
   FUNCTIONS
   ======================================================= */
  
function readJSON( json ) {
	if( json == null || json == "undefined" || json == "[{" || json == "" ) {
		return null;
	}
	var data = $.parseJSON( json );
	return data;
}

function splitIcon(src) {
	var icon = src.replace("/common/icons/", "");
	icon = icon.replace(localStorage.cdnUrl, "");
	icon = icon.replace(".png", "");
	return icon;
}

function changeApplicationTitle(title) {
	if(typeof title !== "undefined") {	
		changeRootText(title);
		$("#app-titles > a").text(title);	
		$('.navbar-brand > span').text(title);
	}
}

function showWizardDialog(type, name)
{		
	if(typeof treeId !== 'undefined') {
		var data = {
			type: type,
			id: treeId,
		};		
		$.post("/wizard", data, function(result) {
			$("#wizard_container").html(result);
			$("#wizard_container").find(".modal-title ").text(name);
			$("#modal_wizard").modal('show');
		})
		.fail(function() {
			alertify.error(_("alert_failed"));
		});
	}
	else {
		alertify.error(_("alert_failed"));
	}
}

// vote for integration module
function voteForIntegration(el,moduleId) {
	var elem = $(el);
	$.ajax({
		type: "POST", 
		url: "/integration/vote/"+moduleId,		
		success: function(json) {
			elem.addClass('dk');
			elem.find(".root-help-add span").text(json.message);
			elem.find(".root-help-add i").attr('class', 'fa fa-thumbs-up fa-lg');
			elem.find(".integration-votes").text(json.votes);			
		},
		fail: function() {
			alertify.error('fail');
		}
	});
	
}



