$(document).ready(function() {	
	
	initTooltips();	
	
    // show orders for application
    $("#show-orders").on("change", function() {    	
        var treeID = $(this).val();        
        window.location.href = "/cartOrder/" + treeID;        
    });    
    
    // change status
    $("#order-detail").on("click", "#order-status-change a", function() {    	
    	var status = $(this).attr('data-type');
    	var orderId = $("#order-id").val();
    	var treeId = $("#order-treeid").val();
    	
    	var data = {
    		orderStatus: status
    	};    	
    	$.ajax({
    		method: "POST",
    		url: "/cartOrder/"+treeId+"/"+orderId,
    		data: data,
    		datatype: "json"
    	})
    	.done(function(json) {    		  		
    		changeOrderStatus(status);
    	})
    	.fail(function(response) {    		
    		alertify.error(_(response.responseText));
    	});
    });
    
    // delete order
    $("#order-delete").on("click", function(e) {
    	e.preventDefault();
    	
    	alertify
    		.okBtn(_( "button_delete" ))
			.cancelBtn(_("button_cancel"))
			.confirm(_("delete_cart_order"), function(i) {   		
    			deleteOrder();    		
    	});    	
    });    
    
    function changeOrderStatus(status) {    	
    	var className = "btn-default";
    	if(status == "new") {
    		className = "btn-info";
    	}
    	else if(status == "processing") {
    		className = "btn-primary";
    	}
    	else if(status == "canceled") {
    		className = "btn-warning";
    	}
    	else if(status == "complete"){
    		className = "btn-success";
    	}
    	
	  	$("#order-status").removeClass("btn-default btn-info btn-warning btn-primary btn-success").addClass(className);
    }
    
    function initTooltips() {
    	$("#order-list").find("a.list-group-item").tooltip();    	
    } 
    
    function deleteOrder() {
    	var url = $("#order-delete").attr('href');
    	var data = {
    		_method: "DELETE"
    	};
    	$.ajax({
    		method: "POST", 
    		url: url,
    		data: data,
    		datatype: "json"    		
    	})
    	.done(function() {
    		alertify.success(_("cart_order_deleted"));
    		setTimeout(function(){
    			var href = $("#order-back").attr("href");
    			window.location.href = href;
    		}, 1000);
    	})
    	.fail(function(response) {
    		alertify.error(_(response.responseText));
    	});
    }
    
});