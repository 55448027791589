// init localization 
var options = {
	dataUrl: "/locale"
};
i18n.init(options);


var ariane_callbacks = {};
var ariane_callbacks_temp = {};
var ariane_callbacks_wysiwyg = {};
var ariane_fileMng_callback = null;
var ariane_relatedMng_callback = null;
var ariane_iconSelector_callback = null;
var ariane_container_fileMng = null;
var ariane_container = null;
var ariane_container_icons = null;
var ariane_container_preview = null;
var ariane_container_relatedMng = null;
var ariane_modified = false;
var ariane_lastIconSelectComponent = null;
var ariane_files = [];
var ariane_relatedNodes = [];
var ariane_previousNodeId = null;
var ariane_wasSaveError = false;
var ariane_needRefresh = false; // if true, after click on "Do not save" button in the alertify confirm reload page content (used in tree.js and sys_list component)
var ariane_listeners = [];
var ariane_loaded = false;
var ariane_nodeId = null;
var ariane_errorInTab = 'internal';

var ariane_languages = ["en_GB", "fr_FR", "es_ES", "ru_RU", "de_DE", "cs_CZ", "sk_SK", "hu_HU", "sv_SE", "pl_PL", "it_IT", "pt_PT", "pt_BR"];

function ariane_modify(eventType, value) {    
	ariane_modified = true;
	
	eventType = eventType || null;
	value = value || null;
	if(eventType !== null || value !== null) {	   
	   ariane_event(eventType, value);
	}
}

function ariane_event(eventType, value) {	
	var validateResult = true;	
    if(ariane_listeners[eventType] !== undefined) {    	
        $.each(ariane_listeners[eventType], function(index, v) {         		
        	if(typeof v === "function") {
        		var result = v(value);	         		  
	            if(eventType == 'validate' && !result) {  	            	  	
	            	validateResult = false;
	            }        
        	}            
        });  
    }
    return validateResult;
}

function ariane_registerListener(name, listener, eventType) {     
    if(ariane_listeners[eventType] === undefined) {        
        ariane_listeners[eventType] = {};
    }    
    ariane_listeners[eventType][name] = listener;    
}

function ariane_unregisterListener(name, eventType) {	
    ariane_listeners[eventType][name] = false;    
    delete ariane_listeners[eventType][name];
}

// unregister all listeners for event
function ariane_unregisterEventListeners(eventType) {
	if(ariane_listeners[eventType] !== undefined) {    	
        $.each(ariane_listeners[eventType], function(index, v) {        	
        	delete ariane_listeners[eventType][index];
        });
    }
}

function ariane_init(container, container_icons, container_preview,
		container_fileMng, container_relatedMng) {
	ariane_container = container;
	ariane_container_icons = container_icons;
	ariane_container_preview = container_preview;
	ariane_container_fileMng = container_fileMng;
	ariane_container_relatedMng = container_relatedMng;
	BrowserDetect.init();	
}

function ariane_registerCallback(evname, callback) {
	ariane_callbacks[evname] = callback;	
}

function ariane_registerTempCallback(evname, callback) {
	ariane_callbacks_temp[evname] = callback;
}

function ariane_registerWysiwygCallback(id, callback) {
	ariane_callbacks_wysiwyg[id] = callback;
}

function ariane_unregisterCallback(evname) {
	ariane_callbacks[evname] = false;
}

function ariane_unregisterTempCallback(evname) {
	ariane_callbacks_temp[evname] = false;
}

function ariane_unregisterTempCallbacks() {
	$.each(ariane_callbacks_temp, function(evname, value) {
		ariane_callbacks_temp[evname] = false;
	});
}

function ariane_unregisterWysiwygCallbacks() {
	$.each(ariane_callbacks_wysiwyg, function(evname, value) {
		ariane_callbacks_wysiwyg[evname] = false;
	});
}

function ariane_callback(evname, val1, val2, val3) {
	if (evname == "save_before") {		
		$.each(ariane_callbacks_wysiwyg, function(evname, value) {			
			if (value) {
				value();
			}
		});
	}
	var value = false;
	if (ariane_callbacks_temp[evname])
		value |= ariane_callbacks_temp[evname](val1, val2, val3);
	if (ariane_callbacks[evname])
		value |= ariane_callbacks[evname](val1, val2, val3);
	value |= !(ariane_callbacks_temp[evname] | ariane_callbacks[evname]);
	return value;
}

function ariane_loadPage(id) {	
	if (!ariane_callback("load_before", id))
		return;
	if (ariane_modified) {	 	    
		if (!ariane_callback("load_modify", id))
			return;
	}
	ariane_modified = false;	
	ariane_nodeId = id;
	ariane_previousNodeId = id;
	ariane_unregisterTempCallbacks();
	ariane_unregisterWysiwygCallbacks();
	ariane_closeIconSelector();
	ariane_closeFileManager();	
	$.post("/page/" + id).done(function(text) {
		//console.log(text);
		if (text == "ERR_USER") {
			ariane_callback("load_error", "user");
		} else if (text == "ERR_INTERNAL") {
			ariane_callback("load_error", "internal");
		} else {
			$(ariane_container).html(text);
			ariane_callback("load_after", id);
		}
	}).fail(function() {	
		//console.log("fail");	
		ariane_callback("load_error", "connection");
	});
}

function ariane_savePage(id) {	
    if (!ariane_callback("save_before", id, name, icon)) {    	
    	return;
    }
    // if JS report any error in the field, show save_error before saving
    var validate = ariane_event('validate', null);        
    if(!validate) {    	
    	ariane_callback("save_error", ariane_errorInTab);    	
    	return $.Deferred().reject();
    }
    
	var data = { _method: 'PUT' };
	var name = "";
	var icon = "";
	var menu = "";	
	
	$(".ariane.cmp").each(function() {		
		var dataval = $(this).val();
		// because of disabled select
		if(dataval === null) {
			return;
		}	
		if (data.length > 0)
			data += "&";
		var cmp = encodeURIComponent($(this).attr("id"));		
		var htmla = $(this).html();
		var srca = $(this).attr("src");
		if ((dataval.length == 0) && (srca))			
			dataval = srca;
		if ((dataval.length == 0) && (htmla))			
			dataval = htmla;
		data[cmp] = dataval;
		if ($(this).attr("id") == "ariane_pageName") 
		    name = $(this).val();		    
		if ($(this).attr("id") == "ariane_pageIcon")
			icon = $(this).attr("src");		
	});
	
	return $.ajax({
		type : "POST",
		async: false,
		url : "/page/" + id,
		data : data
	}).done(function(text) {		
	   if(text.indexOf('ERR') != -1) {	   		
	       ariane_wasSaveError = true;	       
	   }
	   else {	   		
	       ariane_wasSaveError = false;
	       ariane_modified = false;	       
	   }
	   
	   if (text == "ERR_USER") {
			ariane_callback("save_error", "user");
		} else if (text == "ERR_INTERNAL") {
			ariane_callback("save_error", "internal");
		} else {			 		
			var wasError = false;
			var tab = "";
			var regexError = /ERROR_.*/;
			var res = text.split("\n");
			for (var i = 0; i < res.length; i++) {
				try {					
					eval(res[i]);
				} catch (e) {
				}				
				if(regexError.test(res[i])){				    
					tab = res[i].replace("ERROR_", "");
				}
				if (res[i] == "ERR_VERIFICATION") {
					wasError = true;
				}				
			}
			if (wasError) {				
				ariane_callback("save_error", tab);				
			} else {		
			    var pageName = $('#ariane_pageName').val();		    	   
				ariane_callback("save_after", id, pageName, icon);						
			}
		}		
		
	}).fail(function() {	   
		ariane_callback("save_error", "connection");		
	});
}

function ariane_tabSwitchBootstrap(id) {	
	$(".tabEventHelp").trigger('click');
	
	// switching bootstrap pills
	$("#"+id).addClass("active");
	// hide all popovers
	$("[data-toggle='popover']").popover('hide');
	
	// remove hash from URL
	var hash = window.location.hash;
	if(hash !== '') {
		// remove fragment as much as it can go without adding an entry in browser history:
		window.location.replace("#");

		// slice off the remaining '#' in HTML5:    
		if (typeof window.history.replaceState == 'function') {
		  history.replaceState({}, '', window.location.href.slice(0, -1));
		}
	}
}

function ariane_switchTab(id) {	
	if (!ariane_callback("tabswitch_before, id"))
		return;
	$(".ariane.tab").removeClass("selected");
	$("#" + id).addClass("selected");
	$(".ariane.tcontent").removeClass("show");
	$("#" + id + "_content").addClass("show");
	ariane_callback("tabswitch_after", id);
	
}

function ariane_selectIconClick(src) {	
	src = localStorage.cdnUrl + '/common/icons/' + src;
	$(ariane_lastIconSelectComponent).attr("src", src);	
	
	if(ariane_iconSelector_callback !== null) {		
		ariane_iconSelector_callback(src);
	}
	ariane_closeIconSelector();
}

function ariane_selectIcon(component, callback) {	
	if (typeof callback === "function" ) { 
		ariane_iconSelector_callback = callback;
	}	  
	ariane_lastIconSelectComponent = component;
	var jcont = $(ariane_container_icons);
	if(!$.trim(jcont.html()).length) {
		$.ajax({
			type : "GET",
			url : "/iconSelector/"+treeId
		}).done(function(text) {
			jcont.fadeIn();
			jcont.html(text);
			jcont.children('.modal').modal();
		}).fail(function() {
			ariane_callback("system_error", "connection");
		});		
	}
	else {
		jcont.fadeIn();
		jcont.children('.modal').modal();
	}
}

function ariane_closeIconSelector() {	
	ariane_lastIconSelectComponent = null;
	if ($(ariane_container_icons).children().hasClass('in')) {
		$(ariane_container_icons).children().modal('hide');
	}
}

// as fileType accept only "image", "audio", "docs", "routes", "others"
function ariane_fileManager(multiselect, fileType, callback) {
	ariane_filesID = null;
	ariane_fileMng_callback = callback;	
	
	var jcont = $(ariane_container_fileMng);	
	$.ajax({
		type : "GET",
		url : "/fileManager",
		data : "type=" + fileType + "&mltsel=" + multiselect+"&tree=" + treeId
	}).done(function(text) {
		jcont.fadeIn();		
		jcont.html(text);
		jcont.children('.modal').modal();
		
		ariane_loadFileMngTab(fileType, multiselect);
		
	}).fail(function() {
		ariane_callback("system_error", "connection");
	});
}

function ariane_fileManagerForNode(multiselect, fileType, callback) {
	ariane_filesID = null;
	ariane_fileMng_callback = callback;	
	
	var jcont = $(ariane_container_fileMng);
	$.ajax({
		type : "GET",
		url : "/fileManger",
		data : "type=" + fileType + "&mltsel=" + multiselect+"&tree=" + treeId + "&node=" + ariane_nodeId
	}).done(function(text) {
		jcont.fadeIn();		
		jcont.html(text);
		jcont.children('.modal').modal();
		
		ariane_loadFileMngTab(fileType, multiselect);
		
	}).fail(function() {
		ariane_callback("system_error", "connection");
	});
}

function ariane_closeFileManager() {
	if ($(ariane_container_fileMng).children().hasClass('in')) {
		$(ariane_container_fileMng).children().modal('hide');
	}
}

function ariane_showSuccessAddFiles(numberOfFiles) {
	if(numberOfFiles==1){
		alertify.success(_("alert_file_added"));
	}else {
		alertify.success(_("alert_files_added"));
	}
}

function ariane_addSelectedFiles() {
	ariane_saveFilesInfo();
	var numberOfFiles = ariane_files.length;	
	if (numberOfFiles === 0) {		
	    alertify
	    	.okBtn(_('button_confirm_ok'))
			.alert(_("alert_no_file_selected"));
	} else {		
		if (ariane_fileMng_callback)
			ariane_fileMng_callback(ariane_files);
			// null array
			ariane_files = [];
			ariane_closeFileManager();		
		}
}
//delete one file
function ariane_deleteFile(id) {       
	return $.ajax({
		type : "POST",
		url : "/file/" + id,
		data: { _method: 'DELETE' }
	}).fail(function(json) {		
		alertify.error(_(json.responseText));
		return false;
	});
	 
}

// delete more files clicking on the button
function ariane_deleteSelectedFiles(tab, multislct) {
	ariane_saveFilesInfo();
	if (ariane_files.length === 0) {	
	    alertify
	    	.okBtn(_('button_confirm_ok'))	
			.alert(_("alert_no_file_selected"));
	} else {
		var message = _("delete_file_question");
		var success = _("alert_file_deleted");
		if(ariane_files.length > 1) {
			message = _("delete_files_question");
			success = _("alert_files_deleted");
		} 
		alertify
			.okBtn(_("button_delete"))
			.cancelBtn(_("button_cancel"))
			.confirm(message, function(e) {			
				var deleted = 0;			
				for (var i = 0; i < ariane_files.length; i = i + 1) {
					var id = ariane_files[i].id;					
					$("#modal_fileMng").find("#"+id).fadeOut().removeClass('clicked');
					ariane_deleteFile(ariane_files[i].id).done(function() {
						deleted++;
					});
				}
				
				// null array
				ariane_files = [];
				setTimeout(function() {
					if(deleted == 1) {
						alertify.success(_("alert_file_deleted"));
					}
					else if(deleted > 1) {
						alertify.success(_("alert_files_deleted"));
					}
					ariane_loadFileMngTab(tab, multislct);
					
				}, 300);
				$('.nav-pills a[href="#fileMng_' + tab + '"]').tab('show');  
			}, function() {
				ariane_files = [];
			});
	}
}

function ariane_moveSelectedFiles(folderID, treeID) {	
	ariane_saveFilesInfo();
	var numberOfFiles = ariane_files.length;
	if (numberOfFiles === 0) {		
	    alertify
	    	.okBtn(_('button_confirm_ok'))
	    	.alert(_("alert_no_file_selected"));
	} else {	
		var files = ariane_files;	
		// null array
		ariane_files = [];				
		return $.ajax({
			url: '/moveFiles/' + folderID,
			type: 'POST',
			data: {files: files},
			datatype: 'json',
		})
		.done(function(json) {			
			if(numberOfFiles==1){
				alertify.success(_("alert_file_moved"));
			}else {
				alertify.success(_("alert_files_moved"));
			}
		})
		.fail(function() {
			alertify.error(_('save_changes_fail'));
		});		
	}
}

function ariane_saveFilesInfo() {	
	$('.clicked.image-container').each(function() {
		var src = $(this).find('.file-selector').attr('src');
		var id = src.substr(src.lastIndexOf("/") + 1, src.length);
		var name = $(this).find('.file-selector').attr('alt');
		var fileName = name.substr(0, name.lastIndexOf("."));
		var ext = name.substr(name.lastIndexOf(".") + 1, name.length);	
		var file = {};
		file.src = src;
		file.id = id;
		file.name = fileName;
		file.ext = ext;
		file.imgSrc = "/data/image/"+id;
		ariane_files.push(file);
	});	
}

function ariane_loadFileMngTab(type, mltsel, node) {	
	// files from previous tab
	$("#tabs-content").find("div.clicked").removeClass("clicked");	
    hideDeleteButton();
        
	var ajax;
	if (typeof node === "undefined" || node === null) { 
	    ajax = $.ajax({
			type : "POST",
			url : "/loadFiles",			
			data: {type: type, multiselect: mltsel, tree: treeId}
		});
	}	  
	else {
		ajax = $.ajax({
			type : "POST",
			url : "/loadFilesForNode",			
			data: {type: type, multiselect: mltsel, tree: treeId, node: node}
		});		
	}
	ajax.done(function(text) {
			$('#fileMng_'+type).html(text);
		}).fail(function() {
			ariane_callback("system_error", "connection");
		});	
	
}

function ariane_relatedMng(nodeId, selectedIds, group, callback) {	 
	if (group) {		
		if (group === true) {
			group = "true";
		}					
	} else {
		group = "false";
	}
	    
	if(nodeId == 0) {
		nodeId = ariane_nodeId;
	}
	
	if(selectedIds.length === 0) {
		selectedIds = [""];
	}
    var ajaxData = {
        id: nodeId,
        selected: selectedIds,
        group: group
        };     
    
    ariane_relatedMng_callback = callback;
    var jcont = $(ariane_container_relatedMng);     
    
    $.ajax({
        type : "POST",
        url : "/relatedManager",        
        data : ajaxData
    }).done(function(text) {
        jcont.fadeIn();     
        jcont.html(text);
        jcont.children('.modal').modal();
    }).fail(function() {
        ariane_callback("system_error", "connection");
    });
}

//call when user click on the add related button in footer of the modal window
function ariane_addRelatedNodes() {   
    var selected = $("#relatedTree").jstree('get_selected', true);
    var nodes = [];
    $.each(selected, function(index, item) {
    	var node = {};
    	node.id = item.id;
    	var icon = item.icon;
    	icon = icon.replace("/common/icons", "");
    	icon = icon.replace(".png", "");
    	node.icon = icon;
    	node.name = item.text;
    	nodes.push(node);
    });
    
    if (ariane_relatedMng_callback) {
        ariane_relatedMng_callback(nodes);
    }
    ariane_closeRelatedMng(); 
}

function ariane_setRelatedNodes(nodes) {        
    ariane_relatedNodes = nodes;        
}

function ariane_closeRelatedMng() {    
    if ($(ariane_container_relatedMng).children().hasClass('in')) {
        $(ariane_container_relatedMng).children().modal('hide');
    }
}

// call when user click on the x button
function ariane_cancelRelatedMng() {        
    ariane_relatedMng_callback(false);
}

function ariane_parse_ids(value) {     
    var ids = value.split(',');
    return ids;
}

// merge id's in array into string
function ariane_merge_ids(ids) {
    var result = '';
    for(var i=0; i<ids.length; i++){
        if(ids[i] == '') {
            continue;
        }
        else if(i == ids.length-1) {
            result += ids[i];
        }
        else {
            result += ids[i]+',';
        }
    }
    return result;
}   

function ariane_loadingSpinner() {    
    $("#content .vbox").html("<div id=\"overlay\" style=\"opacity:0.8;border-radius: 2px;width:100%;height:400px;top:0;left:0;z-index: 1000;\"></div>");
    
    var target = document.getElementById('overlay');
    var spinner = new Spinner(ariane_spinner_opts).spin(target);
}

function ariane_getTabLang(tab) {    
    
    for(l in ariane_languages) {        
        if(tab.indexOf(ariane_languages[l]) >= 0) {
            return ariane_languages[l];
        }
    }
    return false;
}

function ariane_load_ckeditor(editor, element, config) {	
	$('#'+element).hide();
	
	// show spinner while loading CKeditor
	$("#wysiwyg").html("<div id=\"overlay\" style=\"opacity:0.8;border-radius: 2px;width:100%;height:400px;top:0;left:0;z-index: 1000;\"></div>");
	
	var target = document.getElementById('overlay');
	var spinner = new Spinner(ariane_spinner_opts).spin(target);
	var originData = '';
	
	ariane_show_ckeditor(element, config);
}

function ariane_show_ckeditor(element, config) {	
	var originData;
	/* Try-catch block is because of CKEDITOR attaches editor instance twice in Chrome/Chromium and Opera
	 * It throws "The editor instance "ariane_cmp_description_hu_HU_content_data" is already attached to the provided element"
	 */			
	try {
		var editor = CKEDITOR.replace(document.getElementById(element), {
			customConfig : config
		});

		// after loaded hide spinner
		editor.on('instanceReady', function(e) {									
			$('#wysiwyg').css("display", "none");
			originData = editor.getData();

			editor.on('change', function(e) {				
				if (!ariane_modified) {
					var data = editor.getData();									
					if (originData !== data) {
						ariane_modify();
					}
				}
			});			
		});

		ariane_registerWysiwygCallback(element, function() {
			$("#" + element).html(editor.getData());
		});
	} catch(err) {
		console.log("Chyba v CKEDITOR:");
		console.log(err);
	}
	$("#"+element).css("display", "block");	
	
}

function ariane_hide_popovers() {
	$(document).find("div.popover").each(function() {
		$(this).hide();
	});
}

var ariane_spinner_opts =  {
        lines: 9, // The number of lines to draw
        length: 10, // The length of each line
        width: 7, // The line thickness
        radius: 13, // The radius of the inner circle
        corners: 1, // Corner roundness (0..1)
        rotate: 0, // The rotation offset
        direction: 1, // 1: clockwise, -1: counterclockwise
        color: '#000', // #rgb or #rrggbb or array of colors
        speed: 1.8, // Rounds per second
        trail: 90, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        className: 'spinner', // The CSS class to assign to the spinner
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        top: '50%', // Top position relative to parent
        left: '50%' // Left position relative to parent
};   


