$(document).ready(function() {
	
	// show overview for user
	$("#overview-users").on('click', 'a', function(e) {
		e.preventDefault();
		var userID = $(this).attr('data-id');
		$.ajax({
			method : "GET",
			url : "/overview/" + userID,
			dataType : "html"
		}).done(function(html) {
			$("#overview-content").html(html);
		}).fail(function() {
			alertify.error(_("alert_failed"));
		});
	});
	
	// overview search
	$("#overview-search").on('input', function() {
		var term = $(this).val();		
		if($.trim(term)) {
			$.each($("#overview-users").find('a'), function(index, val) {
				var mail = $.trim($(this).text());			
				if(mail.indexOf(term) == -1) {					
					$(this).hide();					
				}
				else {
					$(this).show();
				}			
			});
		}
		else {
			$("#overview-users").find("a").show();
		}
	});
}); 