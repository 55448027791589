$(document).ready(function() {	
	
	// prevent uncheck checkbox
	$(".response-checkbox").on('click', function(e) {
		e.preventDefault();
		return false;
	});
	
    // show responses for application
    $("#show-response-for-tree").on("change", function() {    	
        var treeID = $(this).val();        
        if(treeID == 'all') {        	
        	window.location.href = "/response";
        }
        else {
	        window.location.href = "/form/" + treeID;        	
        }
    });
    
    // show responses for form
    $("#show-response-for-form").on("change", function() {    	
        var nodeID = $(this).val();
        var treeID = $("#show-response-for-tree").val();
        if(treeID == 'all') {
        	$("#show-response-for-tree").parent('.form-group').addClass('has-error');
        	$("#show-responses-help").show();
        }
        else {
        	if(nodeID == 'all') {
	        	window.location.href = "/form/" + treeID;
	        }
	        else {
		        window.location.href = "/form/" + treeID + "/" + nodeID;        	
	        }
        }
        
    });    
    
    // delete response
    $("#response-delete").on("click", function(e) {
    	e.preventDefault();
    	
    	alertify
    		.okBtn(_( "button_delete" ))
			.cancelBtn(_("button_cancel"))
			.confirm(_("delete_response"), function(i) {    		
    			deleteResponse();
    	});    	
    });    
    
    
    
    function deleteResponse() {
    	var url = $("#response-delete").attr('href');
    	var data = {
    		_method: "DELETE"
    	};
    	$.ajax({
    		method: "POST", 
    		url: url,
    		data: data,
    		datatype: "json"    		
    	})
    	.done(function(json) {    		
    		alertify.success(_("response_deleted"));
    		setTimeout(function(){
    			var href = $("#response-back").attr("href");
    			window.location.href = href;
    		}, 1000);
    	})
    	.fail(function(response) {
    		alertify.error(_(response.responseText));
    	});
    }
    
});